<template>
  <select v-model.lazy="countryValue" class="block p-6">
    <option
      v-for="country in data.countries"
      :key="country.code"
      :value="country.code"
      v-text="country.label"
    />
  </select>
</template>

<script lang="ts" setup>
const currentCountry = useCurrentCountry()
const language = useCurrentLanguage()
const route = useRoute()
const router = useRouter()

const data = await useLocaleData()

const countryValue = computed({
  get() {
    return currentCountry.value
  },
  set(code: string) {
    switchCountry(code)
  },
})

const switchCountry = (code: string) => {
  const newPath = route.path.replace(
    `/${language.value}-${currentCountry.value}`,
    `/${language.value}-${code}`,
  )
  router.push(newPath)
}
</script>
